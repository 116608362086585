import React from 'react'
import * as Yup from 'yup'

import { InlineButton, Link } from '../../../index'
import { useGlobalVariables } from '../../../../utils'
import { useBackendApi, useTranslation } from '../../../../utils/providers'
import { trackRegister } from '../../../../utils/Alphapet/tracking'

import FormBox from '../FormBox'
import { LoginPopupTypes } from '../LoginPopupTypes'

export default function RegisterForm({ setMode }) {
  const { t } = useTranslation()
  const backendApi = useBackendApi()
  const [termsOfServiceUrl, privacyPolicyUrl] = useGlobalVariables([
    'termsOfServicePage',
    'privacyPolicyPage',
  ])

  const handleSubmit = async ({ body, token }) => {
    body.token = token
    return await backendApi.registerUser(body)
  }

  const handleSuccess = () => {
    setMode(LoginPopupTypes.LOGIN)
    trackRegister()
  }

  const createFormData = () => ({
    firstName: {
      name: 'firstName',
      schema: Yup.string().trim().required(t('FORM_ERROR_REQ_FIRST_NAME')),
      value: '',
      error: null,
    },
    lastName: {
      name: 'lastName',
      schema: Yup.string().trim().required(t('FORM_ERROR_REQ_LAST_NAME')),
      value: '',
      error: null,
    },
    email: {
      name: 'email',
      schema: Yup.string()
        .trim()
        .email(t('FORM_ERROR_WRONG_EMAIL'))
        .min(5, t('FORM_ERROR_WRONG_EMAIL'))
        .required(t('FORM_ERROR_REQ_EMAIL')),
      value: '',
      error: null,
    },
    password: {
      name: 'password',
      schema: Yup.string()
        .trim()
        .min(6, t('FORM_ERROR_SHORT_PASSWORD'))
        .max(20, t('FORM_ERROR_LONG_PASSWORD'))
        .required(t('FORM_ERROR_NO_PASSWORD')),
      value: '',
      error: null,
    },
    privacyPolicyRegister: {
      name: 'privacyPolicyRegister',
      schema: Yup.bool().test(
        'privacyPolicyRegister',
        t('LOGINBOX_ERROR_NO_LICENCE'),
        (value) => value === true
      ),
      value: false,
      exclude: true, // must be validated in FE, but is not evaluated in BE
      error: null,
    },
  })

  const createElements = (formData) => [
    {
      type: 'headline',
      label: t('LOGINBOX_REGISTER'),
      className: 'mb-4',
    },
    {
      type: 'errorMessage',
      className: 'mb-4',
    },
    {
      type: 'text',
      label: t('FORM_LABEL_FIRST_NAME'),
      className: 'mb-3',
      data: formData.firstName,
      autoFocus: true,
    },
    {
      type: 'text',
      label: t('FORM_LABEL_LAST_NAME'),
      className: 'mb-3',
      data: formData.lastName,
    },
    {
      type: 'email',
      label: t('FORM_LABEL_EMAIL_ADDRESS'),
      className: 'mb-3',
      data: formData.email,
    },
    {
      type: 'password',
      showConfirm: false,
      showStrength: true,
      className: 'mb-3',
      data: formData.password,
    },
    {
      type: 'checkbox',
      label: t('LOGINBOX_REGISTER_LICENCE')({
        termsOfService: {
          Link,
          props: {
            href: termsOfServiceUrl,
            target: '_blank',
            rel: 'noreferrer',
            className: 'text-underline',
          },
        },
        privacyPolicy: {
          Link,
          props: {
            href: privacyPolicyUrl,
            target: '_blank',
            rel: 'noreferrer',
            className: 'text-underline',
          },
        },
      }),
      className: 'mb-3',
      data: formData.privacyPolicyRegister,
    },
    {
      type: 'submit',
      label: t('LOGINBOX_REGISTER'),
      className: 'mb-4',
    },
    {
      type: 'staticText',
      label: t('LOGINBOX_ALREADY_REGISTERED')({
        Link: InlineButton,
        props: {
          className: 'text-primary',
          onClick: () => setMode(LoginPopupTypes.LOGIN),
        },
      }),
      className: 'info-box m-0 pt-3',
    },
  ]

  return (
    <FormBox
      className="register-box"
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
      createFormData={createFormData}
      createElements={createElements}
      setMode={setMode}
      captchaActionName="signup"
    />
  )
}
