import React, { Component } from 'react'

import { FixedOption, SelectableOptions } from './Options'
import BundleProvider, { BundleContext } from './BundleProvider'

export default class ProductBundleOptions extends Component {
  static contextType = BundleContext

  constructor(props) {
    super(props)
    this.state = {
      showValidationErrors: false,
      currentBundle: false,
    }
  }

  componentDidMount() {
    window.addEventListener('addtocart:validation', this.onAddToCartValidation)
  }

  componentWillUnmount() {
    window.removeEventListener(
      'addtocart:validation',
      this.onAddToCartValidation
    )
  }

  onAddToCartValidation = (evt) => {
    this.setState({
      showValidationErrors: evt.detail.status !== 'ok',
      currentBundle: evt.detail.productId,
    })
  }

  render() {
    const { productData } = this.props
    const { currentBundle, showValidationErrors } = this.state
    const { showBundleOptions, bundleType } = this.context

    const bundleData = productData.bundle_data_only?.options
    if (!bundleData || !showBundleOptions) {
      return null
    }

    if (bundleType === 'fixed') {
      return <FixedOption />
    } else {
      return (
        <SelectableOptions
          showValidationError={showValidationErrors}
          currentProductId={productData.id}
          currentSelectedBundle={currentBundle}
        />
      )
    }
  }
}

export { BundleContext, BundleProvider }
