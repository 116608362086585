import React, { useEffect } from 'react'

import { CenterPopup } from '../../index'

import QuickviewContent from '../QuickviewContent'

function QuickviewPopup({ closeAction, productData }) {
  useEffect(() => {
    window.addEventListener('addtocart:success', closeAction)
    return () => {
      window.removeEventListener('addtocart:success', closeAction)
    }
  }, [])

  return (
    <CenterPopup className="quick-view-popup" closeAction={closeAction}>
      <QuickviewContent productData={productData} />
    </CenterPopup>
  )
}

export default QuickviewPopup
