import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'

import {
  AdditionalProductInfo,
  PopupOverlay,
  ProductActionButton,
  ProductSlider,
  ProductSliderItem,
  QtySelect,
} from '../..'
import { fetchMakairaProducts } from '../../../utils'
import {
  ListPagePricingProvider,
  useFeatureFlags,
  useMediaResize,
  useTranslation,
} from '../../../utils/providers'
import { trackQuickview } from '../../../utils/Alphapet/tracking'

import ProductBundleOptions, { BundleProvider } from '../ProductBundleOptions'
import { LoadingIcon } from '../Icons'
import ProductInfo from './ProductInfo'

function QuickviewContent({ productData }) {
  const [currentProduct, setCurrentProduct] = useState(productData)
  const [groupedRelationData, setGroupedRelationData] = useState([])
  const [qty, setQty] = useState(1)
  const [isLoading, setLoading] = useState(false)
  const { language } = useTranslation()
  const { breakpoints } = useMediaResize()
  const { isFeatureEnabled } = useFeatureFlags()

  const isMobile = breakpoints.downLG
  const currentProductData =
    groupedRelationData.find((product) => product.id === currentProduct.id) ||
    currentProduct

  useEffect(() => {
    let active = true
    setLoading(true)
    fetchMakairaProducts(productData.groupedrelations, language)
      .then((products) => products.filter(({ active }) => active === true))
      .then((products) => {
        if (active) {
          setGroupedRelationData(products)
          trackQuickview(currentProduct)
          setLoading(false)
        }
      })
      .catch(() => {
        if (active) {
          setLoading(false)
        }
      })
    return () => {
      active = false
    }
  }, [])

  return (
    <ListPagePricingProvider productData={currentProduct}>
      <BundleProvider productData={currentProduct}>
        {isLoading && (
          <PopupOverlay>
            <LoadingIcon className="mx-auto" />
          </PopupOverlay>
        )}

        <ProductInfo productData={currentProduct} />

        {isFeatureEnabled('relatedProducts') &&
          productData.groupedrelations.length > 0 && (
            <ProductSlider className="mb-2 text-center">
              {Object.values(groupedRelationData).map((product, index) => (
                <ProductSliderItem
                  key={index}
                  label={product.config_variable_a}
                  isSelected={product.id === currentProduct.id}
                  isDisabled={!product.availability}
                  onClick={() => setCurrentProduct(product)}
                />
              ))}
            </ProductSlider>
          )}

        <ProductBundleOptions productData={currentProduct} />

        <label className="d-block my-3">
          <QtySelect
            className="mt-2"
            isDisabled={!currentProduct.availability}
            min={1}
            max={10}
            qty={qty}
            changeQty={setQty}
            type="inputBox"
          />
        </label>

        <ProductActionButton
          className="w-100"
          qty={qty}
          productData={currentProduct}
          disableVariants={true}
          trackingData={{ origin: 'quickview' }}
        />

        {isMobile ? (
          <AdditionalProductInfo
            productData={currentProductData}
            className="mt-3"
          />
        ) : null}
      </BundleProvider>
    </ListPagePricingProvider>
  )
}

QuickviewContent.propTypes = {
  productData: PropTypes.shape({
    groupedrelations: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
}

export default QuickviewContent
