import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import classNames from 'classnames'

import { useTranslation } from '../../../utils/providers'

import productAdditionalInfo from '../productAdditionalInfo'
import { ToggleSection } from '../..'

const AdditionalProductInfo = ({ productData, className }) => {
  const [activeSection, setActiveSection] = useState(null)
  const { t } = useTranslation()

  const content = productAdditionalInfo(productData, true)
    .map((item) => ({
      ...item,
      label: t(item.label),
    }))
    .filter((item) => item.id !== 'ratings')

  const containerClasses = classNames('detailed-info', className)
  return (
    <div className={containerClasses}>
      {content.map(({ id, component, ...rest }) => {
        const isExpanded = activeSection === id

        const toggleSection = (evt) => {
          evt.preventDefault()
          setActiveSection(isExpanded ? null : id)
        }

        return (
          <ToggleSection
            key={id}
            onToggleSection={toggleSection}
            isExpanded={isExpanded}
            labelElement="h3"
            component={component}
            id={id}
            {...rest}
          />
        )
      })}
    </div>
  )
}

AdditionalProductInfo.propTypes = {
  productData: PropTypes.object,
  className: PropTypes.string,
}

export default AdditionalProductInfo
