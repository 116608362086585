import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import { FormStatus, useForm, useGlobalVariables } from '../../../utils'
import { useTranslation } from '../../../utils/providers'
import {
  AlertBox,
  AlertBoxTypes,
  Button,
  InlineButton,
  Link,
  PopupOverlay,
} from '../../index'
import { LoadingIcon, SuccessIcon } from '../Icons'
import { Checkbox, Input, Password } from '../Form'

import { LoginPopupTypes } from './LoginPopupTypes'

export default function FormBox({
  className,
  createFormData,
  createElements,
  successMessage,
  setMode,
  captchaActionName,
  onSubmit,
  onSuccess,
}) {
  const { t } = useTranslation()
  const [privacyPolicyUrl] = useGlobalVariables(['privacyPolicyPage'])
  const [hasOverlay, setOverlay] = useState(false)

  const { formData, formStatus, response, error, handleChange, handleSubmit } =
    useForm({
      createFormData,
      captchaActionName,
      onSubmit,
    })

  useEffect(() => {
    let timeout
    if (formStatus === FormStatus.PENDING) {
      setOverlay(true)
    } else if (formStatus === FormStatus.ERROR) {
      setOverlay(false)
    } else if (formStatus === FormStatus.SUCCESS) {
      // Add a delay, so we can see the success animation
      timeout = window.setTimeout(() => {
        clearTimeout(timeout)
        setOverlay(false)
        if (typeof onSuccess === 'function') {
          onSuccess(response)
        }
      }, 1100)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [formStatus])

  const getKnownErrorMessage = (error) => {
    const { message } = error || {}
    if (message === 'User does not exist!') {
      return t('LOGINBOX_ERROR_NO_EMAIL_EXISTS')({
        Link: InlineButton,
        props: { onClick: () => setMode(LoginPopupTypes.REGISTER) },
      })
    } else if (message === 'Email not confirmed!') {
      return t('LOGINBOX_ERROR_CONFIRM')
    } else if (message === 'Fehlerhafter Anmeldename oder Passwort') {
      return t('LOGINBOX_ERROR_WRONG_LOGIN')
    } else if (message === 'This customer email already exists') {
      return t('LOGINBOX_ERROR_EMAIL_EXISTS')
    } else if (message === 'Consent not given!') {
      return t('LOGINBOX_REGISTER_CONSENT_ERROR')({
        Link,
        props: {
          href: privacyPolicyUrl,
          target: '_blank',
          rel: 'noreferrer',
        },
      })
    } else if (message === 'Google captcha is invalid.') {
      return t('LOGINBOX_REGISTER_CAPTCHA_ERROR')
    }
    return message
  }

  let OverlayIcon
  if (formStatus === FormStatus.SUCCESS) {
    OverlayIcon = SuccessIcon
  } else if (formStatus === FormStatus.PENDING) {
    OverlayIcon = LoadingIcon
  }

  const elements = createElements(formData)

  return (
    <>
      {hasOverlay && OverlayIcon && (
        <PopupOverlay>
          <OverlayIcon className="mx-auto mb-3" animate={true} />
        </PopupOverlay>
      )}

      <form className={className} onSubmit={handleSubmit}>
        {renderFormElements({
          elements,
          onChange: handleChange,
          setMode,
          formStatus,
          errorMessage: getKnownErrorMessage(error),
          successMessage,
        })}
      </form>
    </>
  )
}

function renderFormElements({
  elements,
  onChange,
  formStatus,
  setMode,
  errorMessage,
  successMessage,
}) {
  return elements?.map(({ type, data, label, className, ...rest }, index) => {
    let extraClasses = className
    if (type === 'headline') {
      return (
        <h1 key={index} className={extraClasses}>
          {label}
        </h1>
      )
    }

    if (type === 'password') {
      return (
        <Password
          key={index}
          data={data}
          className={extraClasses}
          onChange={onChange}
          {...rest}
        />
      )
    }

    if (type === 'email' || type === 'text') {
      return (
        <Input
          key={index}
          data={data}
          label={label}
          id={data.name}
          className={extraClasses}
          onChange={onChange}
          inputMode={type}
          {...rest}
        />
      )
    }

    if (type === 'checkbox') {
      return (
        <Checkbox
          key={index}
          data={data}
          label={label}
          className={extraClasses}
          onChange={onChange}
          {...rest}
        />
      )
    }

    if (type === 'separator') {
      extraClasses = classNames('separator', extraClasses)
      return (
        <div key={index} className={extraClasses}>
          <span>{label}</span>
        </div>
      )
    }

    if (type === 'submit') {
      extraClasses = classNames('input-group', extraClasses)
      return (
        <div key={index} className={extraClasses}>
          <Button
            type="submit"
            className="w-100 submit-login"
            variant="primary"
            id="submit-login"
          >
            {label}
          </Button>
        </div>
      )
    }

    if (type === 'forgotPassword') {
      extraClasses = classNames('d-block text-right font-size-xs', extraClasses)
      return (
        <span key={index} className={extraClasses}>
          <InlineButton
            className="text-primary"
            onClick={() => setMode(LoginPopupTypes.FORGOT_PASSWORD)}
          >
            {label}
          </InlineButton>
        </span>
      )
    }

    if (type === 'staticText') {
      return (
        <p key={index} className={extraClasses}>
          {label}
        </p>
      )
    }

    if (
      type === 'successMessage' &&
      successMessage &&
      formStatus === FormStatus.SUCCESS
    ) {
      return (
        <AlertBox
          key={index}
          type={AlertBoxTypes.SUCCESS}
          className={extraClasses}
        >
          {successMessage}
        </AlertBox>
      )
    }

    if (type === 'errorMessage' && formStatus === FormStatus.ERROR) {
      // dedicated to *global* form errors, not component-errors!
      return (
        <AlertBox
          key={index}
          type={AlertBoxTypes.ERROR}
          className={extraClasses}
        >
          {errorMessage}
        </AlertBox>
      )
    }
  })
}
