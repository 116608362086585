import React from 'react'
import { Link } from '../..'
import {
  useGlobalVariables,
  useTranslation,
  shouldOpenInNewTab,
} from '../../../utils'
import classNames from 'classnames'

export default function ProductPriceHint({ className, openInNewTab }) {
  const { t } = useTranslation()
  const priceHintClass = classNames('text-dark additional-info', className)
  const [shippingUrl] = useGlobalVariables(['shippingUrl'])
  return (
    <div className={priceHintClass}>
      {t('PRICE_HINT_WITH_BASE_PRICE')({
        Component: Link,
        url: shippingUrl,
        ...shouldOpenInNewTab(openInNewTab),
      })}
    </div>
  )
}
