import React from 'react'

import { PopupProductInfo } from '../..'

import ProductPrices from '../PriceInfo/ProductPrices'
import ProductPriceHint from '../PriceInfo/ProductPriceHint'

function ProductInfo({ productData }) {
  return (
    <div className="d-flex flex-column align-items-center quickview-content__header">
      <PopupProductInfo productData={productData} />

      <ProductPrices
        productData={productData}
        classSpecialPrice="font-size-l font-weight-bold"
        classRegularPrice="d-block"
        classUnitPrice="font-size-xxs d-block text-gray-5"
        classContainer="text-center"
      />

      <ProductPriceHint className="font-size-xxs mb-3" />
    </div>
  )
}

export default ProductInfo
