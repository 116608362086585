import React from 'react'
import { PropTypes } from 'prop-types'

import { ConditionalProductLink, Icon, Image, RawHtml } from '../..'
import { getTierIconClass, useTranslation } from '../../../utils'
import { useFeatureFlags } from '../../../utils/providers'

function PopupProductInfo({ productData, disableOutOfStock = false }) {
  const { isFeatureEnabled } = useFeatureFlags()
  const { t } = useTranslation()
  const tierIconClass = isFeatureEnabled('product.animals')
    ? getTierIconClass(productData.tiericon)
    : []
  const manufacturerName = isFeatureEnabled('product.brand')
    ? `${productData.manufacturer_name} ${productData.produkt_linie}`
    : productData.produkt_linie

  return (
    <div className="d-flex flex-column align-items-center w-100 popup-product-info">
      <ConditionalProductLink
        href={productData.url}
        className="font-size-m d-block text-center"
      >
        <strong>{productData.name}</strong>
      </ConditionalProductLink>

      <span className="font-size-xs d-block text-center">
        {manufacturerName}
      </span>

      <div className="position-relative my-3 w-100 text-center">
        {isFeatureEnabled('product.badges') && productData.badge && (
          <RawHtml
            element="div"
            className="popup-product-info__badge"
            html={productData.badge}
            forceStrictMode={true}
          />
        )}

        <div className="position-relative">
          <ConditionalProductLink href={productData.url}>
            <Image srcFull={productData.small_image} />
          </ConditionalProductLink>
          {!disableOutOfStock && !productData.availability && (
            <div className="popup-product-info__overlay">
              <strong className="font-size-m text-uppercase text-dark-5">
                {t('OUT_OF_STOCK')}
              </strong>
            </div>
          )}
        </div>

        {tierIconClass.length > 0 && (
          <div className="popup-product-info__tier-icon">
            {tierIconClass.map((symbol, index) => {
              return <Icon symbol={symbol} key={index} />
            })}
          </div>
        )}
      </div>
    </div>
  )
}

PopupProductInfo.propTypes = {
  productData: PropTypes.shape({
    tiericon: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
    manufacturer_name: PropTypes.string,
    produkt_linie: PropTypes.string,
    badge: PropTypes.string,
    url: PropTypes.arrayOf(PropTypes.string),
    small_image: PropTypes.string,
  }).isRequired,
  disableOutOfStock: PropTypes.bool,
}

export default PopupProductInfo
