import React from 'react'
import { PropTypes } from 'prop-types'
import SlideDown from 'react-slidedown'
import classNames from 'classnames'

import { Icon, InlineButton } from '../..'

function ToggleSection({
  id,
  onToggleSection,
  icon,
  label,
  labelElement = 'h2',
  isExpanded,
  component,
  props,
  className,
}) {
  const containerClass = classNames('border-bottom-1', className)
  const arrowClass = classNames('ml-auto', {
    expanded: isExpanded,
    collapsed: !isExpanded,
  })

  const LabelElement = labelElement
  const ContentElement = component

  return (
    <div id={id} className={containerClass}>
      <LabelElement className="toggle-section__section-label">
        <InlineButton
          onClick={onToggleSection}
          id={`label_${id}`}
          aria-controls={`content_${id}`}
          autoFocus={isExpanded}
        >
          {icon && (
            <Icon
              symbol={icon}
              className="mr-3 font-size-l"
              aria-hidden={true}
            />
          )}
          <span className="flex-grow">{label}</span>
          <Icon
            symbol="cf-chevron-down"
            className={arrowClass}
            aria-hidden={true}
          />
        </InlineButton>
      </LabelElement>
      <SlideDown
        closed={!isExpanded}
        className="mb-3"
        transitionOnAppear={false}
      >
        <section
          id={`content_${id}`}
          aria-labelledby={`label_${id}`}
          aria-expanded={isExpanded}
        >
          <ContentElement {...props} />
        </section>
      </SlideDown>
    </div>
  )
}

ToggleSection.propTypes = {
  id: PropTypes.string,
  toggleSection: PropTypes.func,
  icon: PropTypes.string,
  label: PropTypes.string,
  labelElement: PropTypes.elementType,
  isExpanded: PropTypes.bool,
  component: PropTypes.elementType,
  props: PropTypes.object,
  className: PropTypes.string,
}

export default ToggleSection
