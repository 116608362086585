import React, { useState } from 'react'
import classNames from 'classnames'
import SlideDown from 'react-slidedown'
import PropTypes from 'prop-types'

import { RawHtml } from '../../index'
import { TranslationComponent } from '../../../utils'

export default function ExpandableBox(props) {
  const {
    boxStyle = 'type_1',
    heading = null,
    expandText = <TranslationComponent text="SHOW_MORE" />,
    collapseText = <TranslationComponent text="SHOW_LESS" />,
    content,
    positionBottom = false,
    isFullWidth = false,
  } = props
  const [isExpanded, toggleExpanded] = useState(false)

  const containerClasses = classNames('expandable-box mb-4', {
    container: !isFullWidth,
  })
  const buttonClass = classNames(
    'expandable-box-btn d-flex align-items-center',
    {
      'p-3 border-top-primary border-bottom-primary font-size-sm':
        boxStyle === 'type_1',
      'justify-content-center font-size-sm font-weight-semi-bold':
        boxStyle === 'type_2',
    }
  )
  const buttonTextClass = classNames({
    'ml-auto': boxStyle === 'type_1',
  })
  const arrowClass = classNames('icon cf-chevron-down', {
    collapsed: !isExpanded,
    expanded: isExpanded,
    'ml-auto': boxStyle === 'type_1',
    'ml-1': boxStyle === 'type_2',
  })

  const buttonText = isExpanded ? collapseText : expandText
  const button = (
    <div className={buttonClass} onClick={() => toggleExpanded(!isExpanded)}>
      <span className={buttonTextClass}>{buttonText}</span>
      <i className={arrowClass} />
    </div>
  )

  return (
    <section className={containerClasses}>
      {heading && <h2>{heading}</h2>}
      {!positionBottom && button}
      <SlideDown closed={!isExpanded}>
        <RawHtml element="div" html={content} />
      </SlideDown>
      {positionBottom && button}
    </section>
  )
}

ExpandableBox.propTypes = {
  /**
   * Default: 'type_1'
   */
  boxStyle: PropTypes.oneOf(['type_1', 'type_2']),
  /**
   * Optional headline
   */
  heading: PropTypes.string,
  /**
   * CTA text when the content is collapsed
   */
  expandText: PropTypes.any,
  /**
   * CTA text when the content is expanded
   */
  collapseText: PropTypes.any,
  /**
   * Plain-text or HTML
   */
  content: PropTypes.string,
  /**
   * Default: false
   */
  positionBottom: PropTypes.bool,
}

export { default as expandableBoxVariants } from './variants'
